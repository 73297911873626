import { Observable } from "rxjs";
import { Joint } from "../models/joint";
import { JointDetail } from "src/app/domain/models/joint-detail";
import { ReportResponse } from "../models/report-response";

export abstract class IJointRepository {
    abstract getJointsbyTurbineModelId(farmId: string, turbineId: string, turbineModelId: string): Observable<Joint[]>;
    abstract getJointDetailsById(farmId: string, turbineId: string, jointId: string): Observable<JointDetail>;
    abstract generateJointReportURL(farmId: string, turbineId: string, jointId: string): Observable<ReportResponse>;
}

